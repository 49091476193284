<template>
  <d-b-table
    title="Reported Users"
    :load-path="sever_address"
    :delete-path="sever_address"
    :show-index="true"
    :columns="{
      'User E-Mail': 'userMail',
      'Reported E-Mail': 'reportedMail',
      'Reason': 'reason'
    }"
    />
</template>
<script>
import DBTable from "@/components/DBTable";

export default {
  data(){
    return{
      sever_address: '/report/'
    }
  },
  methods:{
  },
  async mounted () {
  },
  components:{
    DBTable
  }
}
</script>
